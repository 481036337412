import {useEffect, useRef, useState} from "react";
import ContentRibbon from "../ContentRibbon";
import ContentImage from "../ContentImage";
import ContentBlockWrapper from "../ContentBlockWrapper";

export default function BatsokBlock1({parts, sectionTitle, customStyle, link, slidesPerView, part}) {
	if (part && part.parts)
		parts = part.parts;

	if (part) {
		if (!sectionTitle && part.sectionTitle)
			sectionTitle = part.sectionTitle;
		if (!link && part.link)
			link = part.link;
		if (!slidesPerView && part.slidesPerView)
			slidesPerView = part.slidesPerView;
	}

	if (!parts)
		parts = [{},{},{}];
	//for each part, take it's first [images] and make it be part.image

	parts.map((part, i) => {
		if (part.images&& part.images.length > 0) {
			// replace file extension with '-500x300.jpg
			let imageExtension = part.images[0].split('.')[part.images[0].split('.').length - 1];
			part.imageLink = part.images[0].replace(/\.[^/.]+$/, '-500x300.'+imageExtension);
		}
	});


	if (!slidesPerView && parts.length <= 3)
		slidesPerView = parts.length;
	const [slidesPerViewState, setSlidesPerViewState] = useState(slidesPerView?slidesPerView:'auto');
	const swiperRef = useRef(null);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 810 && slidesPerViewState !== 2.2) {
				swiperRef.current.setAttribute('slides-per-view', '2.2');
				setSlidesPerViewState(2.2);
			} else if (window.innerWidth >= 810 && slidesPerViewState) {
				swiperRef.current.setAttribute('slides-per-view', slidesPerViewState);
				setSlidesPerViewState(slidesPerViewState);
			}
		};

		// Initial call to set correct slides-per-view on mount
		handleResize();

		// Event listener for window resize
		window.addEventListener('resize', handleResize);

		// Cleanup function to remove event listener
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [slidesPerView]);


	return <ContentBlockWrapper customStyle={customStyle?customStyle:null}><div className={'batsokBlock1 contentBlock'}>
		{sectionTitle&&<ContentRibbon ribbonText={sectionTitle ? sectionTitle : 'Kategoriboks 1'} link={link}></ContentRibbon>}
		<div className={'batsokBlock1Inner'}>
			<swiper-container className="swiper-1" slides-per-view={slidesPerViewState} navigation="true" ref={swiperRef}
							  space-between="20" grab-cursor="true">
				{parts && parts.map((part, i) => {
						return <swiper-slide key={i} style={{width: 'fit-content',height: '100%'}}>
							<a href={part.permalink && part.permalink} target={'_blank'} rel={'noopener noreferrer'}>

								<ContentImage part={part}>
									{part.imageLink &&
										<img loading="lazy" draggable="false" className={'contentPart1Image'} src={part.imageLink}
											 onError={(ev) => {
												 ev.currentTarget.onerror = null;
												 ev.currentTarget.src = part.imageLink = part.imageLink.replace('-scaled', '');
											 }}
										/>}
								</ContentImage>
								<div className={'contentTextContainer'}>
									{part.category &&
										<div className={'contentPart1Category category'}
											 style={customStyle ? customStyle : null}>
											{part.category.toUpperCase()}
										</div>
									}
									<h2 className={'contentPart1Title'}
										style={customStyle ? customStyle : null}>{part.name ? part.name : 'Tittel'}</h2>
									{part.preface && <p>{part.preface}</p>}
								</div>

							</a>
						</swiper-slide>
				})}
			</swiper-container>
		</div>
	</div>
	</ContentBlockWrapper>
}