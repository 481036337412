import c from "./SelectWithSearch.module.scss";
import {useEffect, useRef, useState} from "react";
import ArrowRightIcon from "../../../svg/ArrowRightIcon";

export default function SelectOneWithSearch({allInputTags, chosenTags, setChosenTags, maxChosenTags, showList, placeHolder, disabled}){
	const inputRef = useRef('');
	const [isOpen, setIsOpen] = useState(false);
	const [suggestionList, setSuggestionList] = useState([]);
	const [listPosition, setListPosition] = useState(0);
	const [suggestionListHidden, setSuggestionListHidden] = useState(false);

	const tagSelect = (tag)=>{
		setChosenTags([tag]);
		setIsOpen(false);
		hideSuggestionList();
	}


	useEffect(() => {
		if (isOpen === true)
			inputRef.current.focus();
	}, [isOpen]);

	const tagUnselect = (index)=>{
		setChosenTags(chosenTags.filter((_, i) => i !== index))
	}

	const onInput = async (ev)=>{
		if (ev.key === "Escape"){
			setSuggestionListHidden(true);
		}else if (suggestionListHidden)
			setSuggestionListHidden(false);

		if (ev.key === "ArrowDown"){
			if (listPosition < suggestionList.length-1) {
				inputRef.current.value = suggestionList[listPosition+1].name;
				setListPosition(listPosition + 1);
			}
			ev.preventDefault();
		}else if (ev.key === "ArrowUp") {
			if (listPosition > 0) {
				inputRef.current.value = suggestionList[listPosition-1].name;
				setListPosition(listPosition - 1);
			}
			ev.preventDefault();
		}else{
			setListPosition(-1);
		}

		if(ev.key !== "Enter")
			return;
		ev.preventDefault();

		if(inputRef.current.value.length < 1)
			return;

		let isDuplicate = false;

		// Check if tag already chosen
		chosenTags.forEach((tag)=>{
			if(tag.name === inputRef.current.value)
				return isDuplicate = true;
		});

		// Check if tag already exists
		allInputTags.forEach((inTag)=>{
			if(inTag.name === inputRef.current.value)
				return isDuplicate = true;
		});


		if (isDuplicate){
			tagSelect(allInputTags.find(t=>t.name === inputRef.current.value));
			//inputRef.current.value = "";
			//setSuggestionListHidden(true);
			return;
		}

	}


	const onInputChange = (ev)=>{
		if (ev.target.value.length < 1)
			return setSuggestionList([]);
		// make a list of suggestions for tag names that are similiar to the one the user is typing
		let suggestions = allInputTags.filter(tag => (
			// Filter out tags that are already chosen
			!chosenTags.find(t=>t.name === tag.name)
			&&
			tag.name.toLowerCase().includes(ev.target.value.toLowerCase()))
		);

		setSuggestionList(suggestions);
	}


	function Tag({tag}){return(
		<div onClick={()=>tagSelect(tag)} className={[c.tag].join(' ')}>{tag.name}
		</div>)
	}

	function SuggestionList(){
		if (!suggestionList.length) return null;
		return <div className={[c.suggestionList, suggestionListHidden?'hidden':''].join(' ')}>
			{suggestionList.map((tag, index)=>{
				let selected = listPosition === index;
				return(<div key={'0'+index} className={selected?c.selected:''}><Tag key={index} tag={tag}></Tag></div>);
			})}
		</div>
	}
	const hideSuggestionList = ()=>{
		setSuggestionList([])
	}

return(<>
<div className={c.root}>
	<div className={c.tagsContainer} onClick={()=>{setIsOpen(!isOpen)}}>
		<div className={c.selectedText}>{(chosenTags?.length>0 && chosenTags.sort((a, b) => a.name.localeCompare(b.name)).map((tag, index) => {
			return <span>{tag.name||'Velg'}</span>
		}))||'Velg'}</div>
		<div className={isOpen?c.containerArrowOpen:c.containerArrow}><ArrowRightIcon/></div>
	</div>

	{isOpen&&<div className={c.inputContainer}>
		<input ref={inputRef} className={'input1'} placeholder={"søk "+placeHolder||"søk"} type={"text"} disabled={disabled}
			   onKeyDown={(ev) => {
				   onInput(ev)
			   }}
			   onInput={(ev) => {
				   onInputChange(ev)
			   }}
		/>
		{maxChosenTags > 1 && <button type={"button"} className={'button1'} onClick={(ev) => {
			ev.key = "Enter";
			onInput(ev)
		}}>Legg til</button>}
		<SuggestionList/>
	</div>}

	{showList&&<details>
		<summary>Stikkord-liste</summary>
		<div className={c.tagsContainer}>
			{allInputTags && allInputTags.sort((a, b) => a.name.localeCompare(b.name)).map((tag, index) => {
				// if tag in chosenTags, return
				if (chosenTags.find(t => t.name === tag.name))
					return;
				return (<Tag key={index} tag={tag}></Tag>);
			})}
		</div>
	</details>}

</div>

</>)}