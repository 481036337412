export default function ArrowRightIcon({height=10, width=10, rotate=false}){
return(
<svg width={width} height={height} viewBox="0 0 7 10" fill="inherit" xmlns="http://www.w3.org/2000/svg" style={{
	transform: rotate? "rotate(180deg)" : "rotate(0deg)",
}}>
	<g clipPath={"url(#clip0_1070_701)"}>
	<path d="M0.886963 8.35546L4.24244 4.99998L0.886963 1.6445L1.55806 0.302307L6.25573 4.99998L1.55806 9.69766L0.886963 8.35546Z" fill="inherit"/>
	</g>
	<defs>
	<clipPath id="clip0_1070_701">
	<rect width="5.36877" height="9.39535" fill="inherit" transform="translate(0.886963 0.302307)"/>
	</clipPath>
	</defs>
</svg>
)}