import advertService from "../../services/AdvertService";

export default function AdvertTrackPixelImage({ad}){
	if (!ad || !ad.wideTrackingImageUrl && !ad.mobileTrackingImageUrl)
		return null;

	return <picture style={{width: '1px', height: '1px', display: 'block'}}>
		<source
			media={`(max-width: ${advertService.mobileMaxWidthPx}px)`}
			srcSet={(ad && ad.mobileTrackingImageUrl) ? ad.mobileTrackingImageUrl : 'data:'}
		/>
		<img
			src={(ad && ad.wideTrackingImageUrl) ? ad.wideTrackingImageUrl : 'data:'}
			sizes={`(max-width: ${advertService.mobileMaxWidthPx}px) 100vw, ${advertService.mobileMaxWidthPx}px`}
		/>
	</picture>
}