import ContentPart1 from "../ContentPart1";
import ContentRibbon from "../ContentRibbon";
import {useEffect, useRef, useState} from "react";
import ContentBlockWrapper from "../ContentBlockWrapper";
import {useLocation} from "react-router-dom";
import "../../../scss/styles2.scss";

export default function ContentBlock2({sectionTitle, parts, customStyle, link, slidesPerView}) {
	const location = useLocation();
	if (!parts)
		parts = [{},{},{}]

	// set preface of all parts to null
	// we dont want to show preface
	let tempParts = [];
	parts.forEach(part => {
		if (typeof part !== 'object')
			return;
		part.preface = null;
		//if !part.article, remove the part from the array
		if (part._id)
			tempParts.push(part);
	});
	parts = tempParts;

	const [slidesPerViewState, setSlidesPerViewState] = useState(slidesPerView ? slidesPerView : '3.2');
	const swiperRef = useRef(null);

	useEffect(() => {
		const handleResize = () => {try{
			if (window.innerWidth < 810 && !slidesPerViewState <= "1.3" && (!slidesPerView <= '1.3')) {
				swiperRef.current.setAttribute('slides-per-view', '1.3');
				swiperRef.current.setAttribute('centered-slides', 'true');
				swiperRef.current.setAttribute('centered-slides-bounds', 'true');
				//swiperRef.current.setAttribute('navigation', 'false');
				//setSlidesPerViewState('1.3');
			} else if (window.innerWidth >= 810) {
				swiperRef.current.setAttribute('slides-per-view', slidesPerViewState);
				setSlidesPerViewState(slidesPerViewState);
			}
		}catch(e){}};

		// Initial call to set correct slides-per-view on mount
		handleResize();

		// Event listener for window resize
		window.addEventListener('resize', handleResize);

		// Cleanup function to remove event listener
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [slidesPerViewState]);

	useEffect(() => {
		swiperRef.current.swiper.slideTo(0);
	}, [location]);


return(<ContentBlockWrapper customStyle={customStyle?customStyle:null}>
	<div className={['contentBlock2 contentBlock'].join(' ')}>
		{sectionTitle && <ContentRibbon ribbonText={sectionTitle ? sectionTitle : 'Kategoriboks 2'} link={link}/>}

		<swiper-container class="swiper-1" slides-per-view={slidesPerViewState} navigation="true" ref={swiperRef}
						  space-between="20" grab-cursor="true"
		>
			{parts.map((part, i) => {
				return <swiper-slide key={i}>
					<ContentPart1 part={part} customStyle={customStyle?customStyle:null}/>
				</swiper-slide>
			})}
		</swiper-container>

	</div>
</ContentBlockWrapper>)
}