import {useState} from "react";
import c from '../other/userFinishSignupForm/UserFinishSignupForm.module.scss';
import Helpers from "../../helpers/Helpers";
import SelectOneWithSearch from "../ui/functional/SelectWithSearch";
import Checkbox from "../ui/Checkbox";
import {useNotification} from "../../store/NotificationContext";

export default function FormView({
 	questions, sendIn, defaultFormAnswers, title, displayStepProgress, firstStepExplanation,
	postponeFunction,
}) {
	const [formAnswers, setFormAnswers] = useState(defaultFormAnswers||{});
	const [errors, setErrors] = useState({});
	const [step, setStep] = useState(0);
	const [transitioning, setTransitioning] = useState(false);
	const { showNotification } = useNotification();

	const [isLoading, setIsLoading] = useState(false);

	console.log(formAnswers);

	const validateInput = (inputObj, value) => {
		let error = "";

		if (!inputObj.dependsOn && inputObj.required && !value) {
			error = "Dette feltet er obligatorisk";
		}else if(!value){

		}
		else if (inputObj.validation){
			let validationErr = inputObj.validation(value);
			if (validationErr)
				error = inputObj.validation(value);
		}
		else{ switch (inputObj.propertyName) {
			case 'dateOfBirth':
				// Require date of birth to be in dd.mm.yyyy format
				if (!/^\d{2}\d{2}\d{4}$/.test(value)) {
					error = "Ugyldig datoformat. Bruk ddmmåååå";
				}
				break;
			case 'countryCode':
			case 'phone.countryCode':
				if (value&& !/^\d{1,3}$/.test(value)) {
					error = "Ugyldig landskode.";
				}
				break;
			case 'phoneNumber':
			case 'phone.phoneNumber':
				if (!/^\d{8,15}$/.test(value)) {
					error = "Ugyldig telefonnummer.";
				}
				break;
			default:
				break;
		}}

		setErrors((prevErrors) => ({ ...prevErrors, [inputObj.propertyName]: error }));
		return error === "";
	};

	const handleChangeForm = (inputObj, value) => {
		validateInput(inputObj, value);
		console.log(inputObj);

		// Create a copy of formAnswers
		const updatedFormAnswers = { ...formAnswers };

		// Split the propertyName into an array of keys
		const keys = inputObj.propertyName.split('.');

		// Traverse and set the value dynamically
		let current = updatedFormAnswers;
		keys.forEach((key, index) => {
			if (index === keys.length - 1) {
				// If it's the last key, set the value
				current[key] = value;
			} else {
				// If the key doesn't exist, initialize it as an empty object
				current[key] = current[key] || {};
				current = current[key];
			}
		});

		setFormAnswers(updatedFormAnswers);
	};


	const nextStep = () => {
		const currentStepQuestions = questions[step];
		const isStepValid = currentStepQuestions.every(question =>
			question.inputs.every(input =>
				validateInput(input, formAnswers[input.propertyName] || "")
			)
		);

		if (isStepValid) {
			setTransitioning(true);
			setTimeout(() => {
				setStep(step + 1);
				setTransitioning(false);
			}, 300);
		}
	};

	const prevStep = () => {
		setTransitioning(true);
		setTimeout(() => {
			setStep(step - 1);
			setTransitioning(false);
		}, 300);
	};

	const submit = async () => {
		// Add final validation before submission if needed
		const isFormValid = questions.every((section) =>
			section.every((question) =>
				question.inputs.every(input => validateInput(input, Helpers.getByDotNotation(formAnswers, input.propertyName) || ""))
			)
		);

		if (isFormValid) {
			setIsLoading(true);
			console.log('LOADING')
			let sent = await sendIn(formAnswers);

			if (sent!==false) showNotification({message:'Lagret', color:'green'})
			else showNotification({message:'Feil ved lagring', color:'red'});
			setIsLoading(false);
		}
	};
	
	return (
		<div className={c.root}>
			<div className={[c.container, isLoading?c.disabled:''].join(' ')}>
				{title && <h3>{title}</h3>}
				{(firstStepExplanation && step === 0) && <>{firstStepExplanation}</>}
				{displayStepProgress && <StepProgressBar step={step} questions={questions}/>}
				<div className={`${c.formTransition} ${transitioning ? c.fadeOut : c.fadeIn}`}>
					<FormQueries
						step={step}
						questions={questions}
						handleChangeForm={handleChangeForm}
						formAnswers={formAnswers}
						isLoading={isLoading}
						errors={errors}
					/>
				</div>
				<div className={c.stepChange}>
					{step > 0 &&
						<button type="button" disabled={isLoading} onClick={prevStep} className={c.stepChangeButton}>Forrige</button>}
					{step < questions.length - 1 &&
						<button type="button" disabled={isLoading} onClick={nextStep} className={c.stepChangeButton}>Neste</button>}
					{step === questions.length - 1 &&
						<button type="button" disabled={isLoading} onClick={submit} className={c.stepChangeButton}>Lagre</button>}
				</div>
				{postponeFunction&&<button type={"button"} onClick={postponeFunction} className={c.postpone}>Utsett</button>}
			</div>
		</div>
	);
}

function StepProgressBar({step, questions}) {
	let maxSteps = questions.length - 1;
	let stepWidthPercent = 100 / maxSteps;

	//make a horizontal progress bar
	return <div className={c.stepProgress}>
		{questions.map((q, i) => {
			if (i===maxSteps) return null;
			let isFilled = i < step;
			return <div key={i} className={[c.stepProgressItem, isFilled?c.stepFilled:''].join(' ')}
				style={{width: '100%', maxWidth: (stepWidthPercent-1)+'%'}}
			></div>
		})}
	</div>
}

function FormQueries({ step, questions, handleChangeForm, formAnswers, errors, isLoading }) {
	return questions[step].map((question) => (
		<fieldset key={question.name}>
			{question.inputs.map((input) => {
				if (input.dependsOn) {
					const dependsOnValue = Helpers.getByDotNotation(formAnswers, input.dependsOn);
					if (!dependsOnValue) {
						return null;
					}
				}
				const error = errors[input.propertyName];

				if (input.inputType === 'radio') {
					return (<>
						{input.name&&<label className={c.fieldsetLabel}>{input.name}</label>}
						<div key={input.propertyName}
							 className={`${c.questionInputWrapper} ${c['question-' + input.propertyName]}`}>
							{input.options.map((option) => (
								<label key={option} className={c.radioLabel}>
									<input type="radio" name={question.propertyName}
										   disabled={isLoading}
										   value={option}
										   onChange={e => handleChangeForm(input, e.target.value)}
										   checked={Helpers.getByDotNotation(formAnswers, input.propertyName) === option}/>
									{option}
								</label>
							))}
						</div>
						{error && <p className={c.errorText}>{error}</p>}
					</>);
				} else if (input.inputType === 'select') {
					return (<>
						{input.name&&<label className={c.fieldsetLabel}>{input.name}</label>}
						<div key={input.propertyName}
							 className={`${c.questionInputWrapper} ${c['question-' + input.propertyName]}`}>
							<select value={Helpers.getByDotNotation(formAnswers, input.propertyName) || ""}
									disabled={isLoading}
									name={question.propertyName}
									onChange={e => handleChangeForm(input, e.target.value)}>
								<option value="">Velg</option>
								{input.options.map((option) => {
									let val = null, name = null;
									if (typeof option === 'object') {
										val = option.value || option._id;
										name = option.name;
									} else {
										val = option;
										name = option;
									}
									return <option key={val} value={val}>{name}</option>
								})}
							</select>
							{error && <p className={c.errorText}>{error}</p>}
						</div>
					</>)
				}
				else if(input.inputType ==='multiSelect'){
					let val = Helpers.getByDotNotation(formAnswers, input.propertyName);
					let changeFunc = (input, value, checked)=>{
						let newVal = null;
						if (!val) newVal = []; else newVal = [...val];
						checked ? newVal.push(value) : newVal.splice(newVal.indexOf(value),1);
						handleChangeForm(input, newVal);
					};
					return (<>
						{input.name&&<label className={c.fieldsetLabel}>{input.name}</label>}
						<div key={input.propertyName}
							 className={`${c.questionInputWrapper} ${c['question-' + input.propertyName]}`}>
							{input.options.map((option) => {
								let checked = false;
								if (val && val.indexOf(option)!==-1) checked = true;
								return <label key={option} className={c.radioLabel}>
									<Checkbox disabled={isLoading} state={checked} changeFunc={(inChecked)=>changeFunc(input, option, inChecked)} label={option} style={{
										marginBottom:'4px'
									}}/>
								</label>
							})}
						</div>
					</>)
				}else if(input.inputType === 'selectWithSearch'){
					let val = Helpers.getByDotNotation(formAnswers, input.propertyName);
					return<>
					{input.name&&<label className={c.fieldsetLabel}>{input.name}</label>}
					<div key={input.propertyName}  className={`${c.questionInputWrapper} ${c['question-' + input.propertyName]}`}>
						<SelectOneWithSearch placeHolder={input.placeholder||null} allInputTags={input.options}
							 chosenTags={val?[{_id:val, name:input.options.find(o=>o._id===val).name}]:[]}
							 setChosenTags={(newVals)=>handleChangeForm(input, newVals[0]._id)}
							 disabled={isLoading}
						/>
					</div>
				</>}
				else if(input.inputType === 'custom'){
					let val = Helpers.getByDotNotation(formAnswers, input.propertyName);
					const CustomComponent = input.custom; // Extract the JSX component
					return <>
						{input.name&&<label className={c.fieldsetLabel}>{input.name}</label>}
						{input.preface && input.preface}
						<CustomComponent value={val} onChange={(newVal)=>handleChangeForm(input, newVal)} disabled={isLoading} />
					</>;
				}
				else {
					return (
						<>
							{input.name&&<label className={c.fieldsetLabel}>{input.name}</label>}
							{input.preface && input.preface}
							<input
								type={input.inputType}
								disabled={isLoading}
								pattern={input.pattern}
								name={input.propertyName}
								minLength={input.min}
								maxLength={input.max}
								onChange={e => handleChangeForm(input, e.target.value)}
								value={Helpers.getByDotNotation(formAnswers, input.propertyName) || ""}
								placeholder={input.placeholder}
							/>
							{error && <p className={c.errorText}>{error}</p>}
						</>
					);
				}
			})}
		</fieldset>
	));
}
