import { createContext, useState, useContext } from "react";
import c from "./NotificationContext.module.scss";

// Create the context
const NotificationContext = createContext();

// Context provider
export const NotificationProvider = ({ children }) => {
	const [notification, setNotification] = useState(null);

	// Function to show a notification
	const showNotification = ({message, duration = 3000, color}) => {
		setNotification({message: message, color: color});
		setTimeout(() => setNotification(null), duration); // Clear after duration
	};

	return (
		<NotificationContext.Provider value={{ showNotification }}>
			{children}
			{notification && (
				<div className={[c.root, c[notification.color]].join(' ')}>
					{notification.message}
				</div>
			)}
		</NotificationContext.Provider>
	);
};

// Custom hook for consuming the context
export const useNotification = () => useContext(NotificationContext);
