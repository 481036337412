import { useEffect, useState } from 'react';

const useInView = (ref, options = {threshold:0,rootMargin:'0px 0px 0px 0px'}, onInView, runOnce = false, onOutView) => {
	const [inView, setInView] = useState(false);
	const [hasRun, setHasRun] = useState(false);

	useEffect(() => {
		if (!ref.current) return;

		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					// Element is in view
					if (!inView) {
						setInView(true);
						console.log('INVIEW');
						if (onInView) {
							if (!runOnce || !hasRun) {
								onInView();
								if (runOnce) {
									setHasRun(true); // Ensure the callback runs only once
								}
							}
						}
					}
				} else {
					// Element is out of view
					if (onOutView) {
						onOutView();
					}
					setInView(false);
				}
			},
			{
				threshold: options?.threshold || 0,
				rootMargin: options?.rootMargin || '0px 0px 0px 0px',
			}
		);

		observer.observe(ref.current);

		// Clean up observer on unmount
		return () => {
			ref.current &&
				observer.unobserve(ref.current);
		};
	}, [ref, inView, hasRun, runOnce, onInView, onOutView, options]);

	return inView;
};

export default useInView;
