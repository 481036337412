import ContentPart1 from "../ContentPart1";
import ContentBlockWrapper from "../ContentBlockWrapper";

export default function ContentBlock3({parts, customStyle}){
	let part = {};
	if (!parts) {
		part = {
			name: 'Gå til bæsj og ta en drit i bar',
			preface: 'KJAWdh paiudhawiu dhOAWUd hawdh9 129hd921q nd+2" AHD aWd AdlkjAHWd hAK DwAd øhA',
		}
		console.log('ContentBlock3 has no parts');
	}else{
		part = parts[0];
	}

	if (customStyle)
		console.log(customStyle)

	return <ContentBlockWrapper customStyle={customStyle?customStyle:null}>
	<div className={'contentBlock3 contentBlock'}>
		<ContentPart1 part={part} useFullSizeImage={true}/>
	</div>
	</ContentBlockWrapper>
}