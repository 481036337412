import SiteService from "../services/SiteService";

const Helpers = {};

Helpers.countLines = function (el) {
	lineCount(el)
}
const removeStyleProp = (elm, props) =>
	elm.style.cssText = elm.style.cssText.split('; ')
		.filter(sp => props.every(p => !sp.startsWith(p)) )
		.join(';');

// deals with editable content, where user can hit "ENTER" or "SHIFT+ENTER"
// or complex content with nested block-level elements and <br>s
const addMeasureStyles = () => {
	document.head.insertAdjacentHTML("beforeend", `<style id="measureSingleLineHeight">
    .measureSingleLineHeight{ position: absolute !important; }
      .measureSingleLineHeight br{ display: none !important; }
      .measureSingleLineHeight *{ display: inline !important; }
  </style>`)
}

function lineCount(elm) {
	const originalStyle = elm.style.cssText
	const computedStyles = getComputedStyle(elm)
	const isBorderBox = computedStyles['box-sizing'] == 'border-box'
	const boxVerticalPadding = ['padding-top', 'padding-bottom'].reduce((acc, p) => acc + parseInt(computedStyles[p]) , 0)
	let initialScrollHeight  // with original line-height & height
	let scrollHeight // with full normalization - "line-height:0 & "height:auto"

	// normalize (should probably be done to all children also via treeWalker)
	// if "box-sizing: border-box;" skip next steps (but do them later for measuring single-line height)
	if( !isBorderBox ){
		elm.style.padding = 0 // clear restrains that might harm measuring
		elm.style.border = 0 // clear restrains that might harm measuring
	}

	initialScrollHeight = elm.clientHeight - (isBorderBox ? boxVerticalPadding : 0)

	addMeasureStyles()
	elm.classList.add('measureSingleLineHeight')
	elm.style.whiteSpace = 'nowrap' // make the whole text a single line, to measure line-height
	elm.style.height = 'auto' // make the height fit exactly to the single-line
	elm.style.padding = 0 // clear restrains that might harm measuring
	elm.style.border = 0 // clear restrains that might harm measuring

	const initialLineHeight = elm.scrollHeight - 1; // fix inaccurate height

	elm.style.minHeight = 0   // clear restrains that might harm measuring
	elm.style.lineHeight = 1  // normalize line-height to fit the font perfectly

	// measure
	const singleLineHeight = elm.scrollHeight  // easy with "white-space:nowrap"

	// cleanup
	measureSingleLineHeight.remove()
	elm.classList.remove('measureSingleLineHeight')
	removeStyleProp(elm, ['white-space', 'border', 'padding']); // bring back original border & padding because they affect the number of rendered lines (border-box)

	scrollHeight = elm.scrollHeight - (isBorderBox ? boxVerticalPadding : 0)

	console.log(initialScrollHeight , initialLineHeight)
	const lineCount = Math.round(scrollHeight / singleLineHeight)
	const linesFit = Math.floor(initialScrollHeight / initialLineHeight)

	// undo above style changes
	elm.style.cssText = originalStyle

	return {
		lineCount: (isNaN(lineCount) || singleLineHeight == 0) ? 0 : lineCount,
		linesFit
	}
}

Helpers.getByDotNotation = function(el, dotPath){try {
	return dotPath.split('.').reduce((a, b) => a[b], el);
}catch(e){return undefined;}}

Helpers.scrollToTargetOffset = function (element, offset){
	if (!offset)
		offset = 62; //height of header
	let elementPosition = element.getBoundingClientRect().top;
	let offsetPosition = elementPosition + window.pageYOffset - offset;

	window.scrollTo({
		top: offsetPosition,
		behavior: "smooth"
	});
}

Helpers.getISODate = function (date) {//defaults to today
	if(!date)
		date = new Date();
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
}

Helpers.getNestedField = function(obj, fieldPath) {try{
	return fieldPath.split('.').reduce((acc, part) => acc && acc[part], obj);
}catch(e){
	return null;
}}

Helpers.getRandomFromArray = function (array) {
	return array[Math.floor(Math.random() * array.length)];
}

Helpers.loadScript = async function (src) {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = src;
		script.async = true;
		script.onload = () => resolve();
		script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
		document.body.appendChild(script);
	});
}

Helpers.getWeekNumber = (date) => {
	const d = new Date(date);
	const yearStart = new Date(d.getFullYear(), 0, 1);
	const weekNo = Math.ceil((((d - yearStart) / 86400000) + yearStart.getDay() + 1) / 7);
	return `${d.getFullYear()}-W${weekNo < 10 ? '0' + weekNo : weekNo}`;
};

Helpers.convertToCSV = (data, includedFields = null) => {
	if (!data || !data.length) {
		return null;
	}

	let transformedData = [...data]
	transformedData.forEach((obj)=>{
		if (obj.date)
			obj.date=obj.date.substring(0,19);
	})

	// Determine the fields to include
	const keys = includedFields ? includedFields : Object.keys(transformedData[0]);
	const csvRows = [];

	// Add the header row
	csvRows.push(keys.join(','));

	// Add the data rows
	transformedData.forEach(row => {
		const values = keys.map(key => {
			const escapedValue = ('' + row[key]).replace(/"/g, '""');
			return `"${escapedValue}"`;
		});
		csvRows.push(values.join(','));
	});

	return csvRows.join('\n');
};

Helpers.downloadCSV = (csvContent, fileName) => {
	const blob = new Blob([csvContent], { type: 'text/csv' });
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.setAttribute('hidden', '');
	a.setAttribute('href', url);
	a.setAttribute('download', fileName);
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

Helpers.uploadWithProgress = function (body, url, httpMethod, setProgress, onEnd, extraPostProperties) {
	const xhr = new XMLHttpRequest();

	xhr.upload.onprogress = function(event) {
		if (!setProgress)
			return;
		const progress = Math.round((event.loaded / event.total) * 100);
		setProgress(progress);
	};

	xhr.onreadystatechange = function() {
		if(xhr.readyState === 4) {
			if(xhr.status === 200) {

				if (onEnd)
					onEnd(JSON.parse(xhr.responseText));
			}
			else {
				onEnd(null);
				alert('Feil under opplasting.')
			}
		}
	}

	xhr.open(httpMethod, url, true);
	xhr.setRequestHeader('Content-Type', 'application/json');

	let sendObj = body;
	console.log(extraPostProperties)
	if (extraPostProperties)
		sendObj = Object.assign(sendObj, extraPostProperties);
	console.log(extraPostProperties)
	xhr.send(JSON.stringify(sendObj));
}

Helpers.downloadWithDifferentName = function (url, fileName) {
	fetch(url)
		.then(res => {
			return res.blob();
		})
		.then(blob => {
			const href = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.download = fileName;
			a.href = href;
			a.click();
			a.href = "";
		})
		.catch(err => console.error(err));
}



Helpers.splitDragElement = function (element, direction){
	//	https://stackoverflow.com/a/55202728

	var   md; // remember mouse down info
	const first  = document.getElementById("first");
	const second = document.getElementById("second");

	element.onmousedown = onMouseDown;

	function onMouseDown(e)
	{
		//console.log("mouse down: " + e.clientX);
		md = {e,
			offsetLeft:  element.offsetLeft,
			offsetTop:   element.offsetTop,
			firstWidth:  first.offsetWidth,
			secondWidth: second.offsetWidth
		};

		document.onmousemove = onMouseMove;
		document.onmouseup = () => {
			//console.log("mouse up");
			document.onmousemove = document.onmouseup = null;
		}
	}

	function onMouseMove(e)
	{
		//console.log("mouse move: " + e.clientX);
		var delta = {x: e.clientX - md.e.clientX,
			y: e.clientY - md.e.clientY};

		if (direction === "H" ) // Horizontal
		{
			// Prevent negative-sized elements
			delta.x = Math.min(Math.max(delta.x, -md.firstWidth),
				md.secondWidth);

			element.style.left = md.offsetLeft + delta.x + "px";
			first.style.width = (md.firstWidth + delta.x) + "px";
			second.style.width = (md.secondWidth - delta.x) + "px";
		}
	}
}

Helpers.splitLastOccurrence = function (str, substring){
	const lastIndex = str.lastIndexOf(substring);

	const before = str.substring(0, lastIndex);
	const after = str.substring(lastIndex + 1);
	return [before, after];
}

Helpers.convertDateToInputValue = function (date) {
	if (typeof date === 'string') date = new Date(date);

	const day = String(date.getDate()).padStart(2, '0'); // Ensures 2 digits
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
	const year = date.getFullYear();
	return `${year}-${month}-${day}`;
};

Helpers.prepareArticleForFrontpageParts = function (part, categories){
	if(part.image) {
		part.imageLink = `${SiteService.apiPath}/image/articles/${part._id}/${part.image.url}`
		if (window.innerWidth <= 768)
			part.imageLink = part.imageLink.replace('header.', 'header-medium.');
	}

	if(part.urlString) part.linkTo = `/artikkel/${part.urlString}`

	if (part.categories && part.categories.length > 0) {try{
		// find the category for the part's first category
		part.category = categories.find((cat) => {
			return cat._id === part.categories[0];
		}).name;
	}catch(e){
		console.error('Could not find category for part', part);
	}}
}

Helpers.postJSON = async function (url, data, options = {}) {
	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				...options.headers,
			},
			body: JSON.stringify(data),
			...options,
		});

		if (!response.ok) {
			console.error(new Error(`HTTP error! Status: ${response.status}`));
			return false;
		}

		// Check if response body has content
		const contentType = response.headers.get('Content-Type');
		if (contentType && contentType.includes('application/json')) {
			// Return JSON data if content type is JSON
			return await response.json();
		}

		// Return true if no JSON data in response
		return true;
	} catch (error) {
		console.error('Error in postJSON:', error);
		throw error;
	}
};

Helpers.searchPaginated = async function (searchIn,searchParams: { region: string, type: string }, searchPage = 0, options) {
	let req = await fetch(`${SiteService.apiPath}/search/${searchIn}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				pageOptions: {
					page:searchPage,
					searchObj: {...searchParams},
					...options
				}
			}),
		}
	);
	let data = await req.json();
	return data;
};

export default Helpers;