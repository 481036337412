import { useState, useEffect } from 'react';
import knbfLogo from "../../images/knbf-logo.png";
import c from "./KNBFBoatForm.module.scss";
import Checkbox from "../../components/ui/Checkbox";
import {useParams} from "react-router-dom";
import litenBat from "./knbf-boat-forms/liten-bat";
import KnbfControleveryboatIcon from "./Knbf-controleveryboat-icon";
import KnbfControlmonthlyIcon from "./Knbf-controlmonthly-icon";
import KnbfControlyearlyIcon from "./Knbf-controlyearly-icon";
import OnTopBox from "../../components/ui/onTopBox";
import KnbfInfoIcon from "./Knbf-infoIcon";


export default function KNBFBoatForm() {
	const [scriptLoaded, setScriptLoaded] = useState(false);
	const {formName} = useParams();
	const [modalOpen, setModalOpen] = useState(false);

	const forms = {
		"liten-bat": litenBat,
	}
	const form = forms[formName] || null;
	if (!form) return <h1>Fant ikke skjema</h1>;

	const [formInput, setFormInput] = useState({});
	const [tableQuestionsInput, setTableQuestionsInput] = useState(form.tableQuestions);


	// Import pdfMake and vfs_fonts.js dynamically
	async function start() {
		await import('../../redis/pdfmake.min.js');
		await import('../../redis/vfs_fonts.js'); // Load vfs_fonts.js for fonts
		setScriptLoaded(true);
	}

	const [logoBase64, setLogoBase64] = useState("");
	const [checkMarkB64, setCheckMarkB64] = useState("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAXUlEQVQ4jeXPMRKAIAxE0Z/bxJPQeDYKxnPaaoeFS8NIEcaOnUnH2xBYLgZk4AR8Bh9ABa5ogQFF+Ab237Hz3pUFRt9Ooy2uB1XAIrgldSUh/FUSxn3JFG7ZNCvkASXaIOs21V2UAAAAAElFTkSuQmCC");

	useEffect(() => {
		fetch(knbfLogo)
			.then((response) => response.blob())
			.then((blob) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					setLogoBase64(reader.result); // Base64 string
				};
				reader.readAsDataURL(blob);
			});
	}, []);


	useEffect(() => {
		if (!scriptLoaded) start();
	}, [scriptLoaded]);

	if (!scriptLoaded) {
		return <h4>Laster...</h4>;
	}

	let size1 = 9;
	let color1 = '#00205B';
	let color2 = '#00b3aa';

	const getTable = (tableQuestions) => {
		return tableQuestions.map((q, index) => {
			if (q.header) {
				return [
					{
						text: q.header,
						bold: true,
						colSpan: 4,
						margin: [0, 10, 0, 0],
						border: [false, false, false, false],
					},
					'',
					'',
					''
				];
			}

			// Return other table rows for non-header elements, if needed
			let fill = index % 2? '' : '#f1f1f1';
			return [
				{text: q.text || '', fontSize: size1, fillColor: fill},
				q.checked?{image: checkMarkB64,width: 10, alignment:'center', fontSize: size1, fillColor: fill}:{text:'',fillColor: fill},
				{text: q.date?.split('-').reverse().join('-') || '', fontSize: size1, fillColor: fill},
				{text: q.textAnswer || '', fontSize: size1, fillColor: fill}
			];
		});
	};

	// The function to generate the PDF from the formInput data
	const makePdf = (form) => {
		console.log(tableQuestionsInput)
		let table = getTable(tableQuestionsInput);

		const docDefinition = {
			pageSize: 'A4',
			content: [
				{
					canvas: [
						{
							type: 'rect',
							x: 0,
							y: 0,
							w: 900,
							h: 110,
							color: color2,
						}
					],
					absolutePosition: { x: 0, y: 0 } // Position relative to the page
				},
				{
					image: logoBase64, // Replace with your base64 image string
					width: 50, // Adjust width as needed
					absolutePosition: { x: 490, y: 18 } // Adjust coordinates for top-right placement
				},
				{ text: form.title, fontSize: 18, bold: true, alignment: 'left', color:'white' },
				{ text: 'Denne sikkerhetskontrollen for egenkontroll av fritidsbåt er laget av KNBF.', color:'white', margin: [0, 10, 10, 35] },
				{
					columns: [
						{
							text: [
								{ text: 'Båtnavn: ', bold: true, fontSize: size1 },
								{ text: formInput.båtnavn || '', bold: false, fontSize: size1 }
							],
						},
						{
							text: [
								{ text: 'Båtmodell: ', bold: true, fontSize: size1 },
								{ text: formInput.båtmodell || '', bold: false, fontSize: size1 }
							]
						},
						{
							text: [
								{ text: 'Skrognummer/CIN: ', bold: true, fontSize: size1 },
								{ text: formInput.skrognummer || '', bold: false, fontSize: size1 }
							]
						},
						'',
					],
					margin: [0, 10]
				},
				{
					columns: [
						{
							text: [
								{ text: 'Tillv.år: ', bold: true, fontSize: size1 },
								{ text: formInput.båttype || '', bold: false, fontSize: size1 }
							]
						},
					],
					margin: [0, -7, 0, 0]
				},
				{
					columns: [
						{
							text: [
								{ text: 'Motornr.: ', bold: true, fontSize: size1 },
								{ text: formInput.motornr || '', bold: false, fontSize: size1 }
							]
						},
						{
							text: [
								{ text: 'Drevnr.: ', bold: true, fontSize: size1 },
								{ text: formInput.motornr || '', bold: false, fontSize: size1 }
							]
						},
						{
							text: [
								{ text: 'Stoldskydds-ID.: ', bold: true, fontSize: size1 },
								{ text: formInput.motornr || '', bold: false, fontSize: size1 }
							]
						},
						{
							text: [
								{ text: 'Dato: ', bold: true, fontSize: size1 },
								{ text: formInput.motornr || '', bold: false, fontSize: size1 }
							]
						},
					],
					margin: [0, 10]
				},

				// Kontrollpunkt Table
				{
					table: {
						widths: ['*', 'auto', 'auto', 'auto'],
						body: [
							[{ text: 'Kontrollpunkt', bold: true, fillColor: color1, color:'white', fontSize: '10' },
								{ text: 'Kontrollert', bold: true, fillColor: color1,color:'white', fontSize: '10' },
								{ text: 'Dato', bold: true , fillColor: color1,color:'white', fontSize: '10'},
								{ text: 'Notat', bold: true, fillColor: color1,color:'white', fontSize: '10' }
							],
							...table,
							[
								{
									text: 'Ekstra notat',
									bold: true,
									colSpan: 4,
									margin: [0, 10, 0, 0],
									border: [false, false, false, false],
								},
								'',
								'',
								''
							],
							[{text: formInput.extraNote, fontSize: size1, colSpan:4}, '','','']
						],
					},
					margin: [0, 10],
					layout: {
						hLineColor: () => 'gray', // Horizontal line color
						vLineColor: () => 'gray', // Vertical line color
						hLineWidth: () => 0.5,      // Horizontal line width
						vLineWidth: () => 0.5       // Vertical line width
					},
				},
			]
		};

		// Generate and open the PDF
		pdfMake.createPdf(docDefinition).open();
	};

	const formTopQuestions = [
		{name: 'Båtnavn', propertyName: 'båtnavn' },
		{name: 'Båtmodell', propertyName: 'båtmodell' },
		{name: 'Skrognummer/CIN', propertyName: 'skrognummer' },
		{name: 'Motornr.', propertyName: 'motornr' },
		{name: 'Drevnr.', propertyName: 'drevnr' },
		{name: 'Stöldskydds-ID', propertyName: 'stoldskyddsID' },
		{name: 'Dato', propertyName: 'dato', value: '2024-11-25' }
	];

	const formChange = (propName, value) => {
		setFormInput({...formInput, [propName]: value});
	}

	return (<>
		<div className={c.root}><div className={c.container}>
			<div className={c.header}>
				<h1>{form.title}</h1>
				<p>{form.formDescription}</p>
				<img src={knbfLogo} alt="Knbf logo" className={c.logo}/>
			</div>
			<div className={c.main}>
				<div className={c.legend}>
					<div className={c.legendItem}><KnbfControleveryboatIcon/><span>Kontroller hver båttur</span></div>
					<div className={c.legendItem}><KnbfControlmonthlyIcon/><span>Kontroller månedlig</span></div>
					<div className={c.legendItem}><KnbfControlyearlyIcon/><span>Kontroller årlig</span></div>
				</div>
				<div className={c.inputGroups}>
				{formTopQuestions.map((q,i)=>{return <div key={i} className={c.inputGroup}>
					<label>{q.name}</label>
					<input type="text" placeholder={q.name} value={formInput[q.propertyName] || ''}
								  onChange={(e) => formChange(q.propertyName, e.target.value)} />
				</div>})}
				</div>
				<ExtraQuestionsInput extraQuestions={tableQuestionsInput} setExtraQuestions={setTableQuestionsInput} setModalOpen={setModalOpen}/>

				<label>
				<h3>Ekstra notat:</h3>
				<textarea className={c.extraTextArea} onChange={(e)=>formChange('extraNote', e.target.value)} value={formInput.extraNote || ''} />
				</label>
				<br/>
				<button className={c.submitButton} onClick={() => makePdf(form)}>Generer PDF</button>
			</div>
		</div></div>
		<OnTopBox setOnTopEnabled={setModalOpen} onTopEnabled={modalOpen} onOk={()=>{setModalOpen(null)}}>
			{modalOpen}
		</OnTopBox>
	</>);
}


function ExtraQuestionsInput({ extraQuestions, setExtraQuestions, setModalOpen }) {
	const handleInputChange = (index, field, value) => {
		setExtraQuestions((prev) => {
			const updatedQuestions = [...prev];
			updatedQuestions[index] = {
				...updatedQuestions[index],
				[field]: value
			};
			return updatedQuestions;
		});
	};

	return (
		<div className={c.tableQuestionSection}>
			{extraQuestions.map((question, index) => {
				if (question.header){
					return <h2>{question.header}</h2>
				}
				return <div key={index} className={c.tableInputGroup}>
					<div className={c.checkBoxGroup}>
						<span className={c.formQuestionText}>
							{question.legendIcon&&<>
								{question.legendIcon === 'b' && <KnbfControleveryboatIcon/>}
								{question.legendIcon === 'm' && <KnbfControlmonthlyIcon/>}
								{question.legendIcon === 'y' && <KnbfControlyearlyIcon/>}
							</>}
							{question.text}
							{question.modalContent && <button className={c.modalOpenButton} onClick={()=>{setModalOpen(question.modalContent)}}><KnbfInfoIcon/></button>}
						</span>
							{/* Checkbox input */}
							<Checkbox label={'Kontrollert'} state={question.checked}
								  changeFunc={(newVal) => handleInputChange(index, 'checked', newVal)} />
					</div>

				{question.checked&&<div className={c.questionInputGroup}>
					{/* Date input */}
					<input
						type="date"
						value={question.date || ''}
						onChange={(e) => handleInputChange(index, 'date', e.target.value)}
					/>

					{/* Text input */}
					<textarea
						placeholder="Notat"
						value={question.textAnswer || ''}
						onChange={(e) =>
							handleInputChange(index, 'textAnswer', e.target.value)
						}
					/>
				</div>}
				</div>
			})}
		</div>
	);
}