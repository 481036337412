import VideoPlayer from "../ui/VideoPlayer";
import PlayIcon from "../../svg/PlayIcon";
import plussIcon from "../../images/icons/pluss.svg";
import {useRef} from "react";
import useInView from "../../hooks/useInView";

export default function ContentImage({part, children, customStyle}){
	if(part.isSponsored)
		part.category = 'Annonsørinnhold';

	const videoRef = useRef(null);

 	return <div className={'contentImageContainer'}>
		{part.video?<VideoPlayer ref={videoRef} videoId={part.video} autoPlay={true} disableControls={true}/> : children}
		{part.pluss && <img className={'plussIcon'} src={plussIcon} alt={'Pluss ikon'}></img>}
		{part.hasVideo && <div className={'hasVideoIcon'}><PlayIcon/></div>}
		{part.category && <div style={customStyle?{color: customStyle.color, background:customStyle.background}:null}
							   className={['categoryInImage categoryBoxThemed',
								   part.isSponsored?'sponsored':'',
								   part.isOpinionPiece?'opinionPiece':''
							   ].join(' ')}>
			{part.category}</div>}
	</div>
}