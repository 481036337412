import {useContext, useEffect, useState} from "react";
import c from './UserFinishSignupForm.module.scss';
import OnTopBox from "../../ui/onTopBox";
import siteService from "../../../services/SiteService";
import {useNavigate} from "react-router-dom";
import FormView from "../../FormView/FormView";
import SiteContext from "../../../store/siteContext";
import classes from "../../../pages/admin/ArticleEdit.module.scss";
import CategoryCheckbox from "../../articleEdit2/CategoryCheckbox";
import categoryService from "../../../services/CategoryService";
import UserContext from "../../../store/userContext";
import Helpers from "../../../helpers/Helpers";

export default function UserFinishSignupForm() {
	const [isVisible, setIsVisible] = useState(true);
	const navigate = useNavigate();
	const [boatBrands, setBoatBrands] = useState(null);
	const categories = categoryService.sortCategoriesAdjacentToParent(useContext(SiteContext).categories);
	const {name, setIsProfileCompleted} = useContext(UserContext);
	useEffect(() => {
		if (!boatBrands) getBoatBrands();
	}, []);


	const sendIn = async (formAnswers) => {
		formAnswers.profileCompletion = true;
		let res = await Helpers.postJSON(`${siteService.apiPath}/user/submitprofileupdateform`, formAnswers);
		if (res) {
			setIsProfileCompleted(true);
			navigate('/');
		}
		else return false;
	};

	const postPone = async ()=>{
		let sendReq = await fetch(`${siteService.apiPath}/user/postpone-profile-completion`);
		if (sendReq.ok) {
			setIsProfileCompleted(true);
			navigate('/');
		}
	}

	const getBoatBrands = async () => {
		let brands = await fetch(`${siteService.apiPath}/batglede/boatbrands`);
		if (!brands.ok) {
			alert('Feil under henting av båt-merker. Prøv igjen senere.');
			return null;
		}

		brands = await brands.json();
		setBoatBrands(brands);
	}

	function CategoryChooser({value, onChange}) {
		return <div className={classes.categoriesContainer}>
			{categories.map((category, index) => {
				return (
					<CategoryCheckbox key={index}
									  categories={value||[]}
									  category={category}
									  setCategories={(newCats) => {
										  onChange(newCats)
									  }}
									  index={index} classes={classes}></CategoryCheckbox>
				)
			})}
		</div>
	}

	const questions = [
		[
			{
				inputs: [
					{name: 'Hvilket båtmerke har du?',propertyName: 'boatBrand', inputType: 'selectWithSearch', placeholder: 'båt-merke', options: boatBrands||[]},
					{name: 'Modell-betegnelse',propertyName: 'boatModel', inputType: 'text', placeholder: '705 DC', dependsOn: 'boatBrand'},
				]
			},
		],
		[
			{
				inputs: [
					{name: 'Planlegger du å kjøpe ny båt innen 5 år?',propertyName: 'plansToBuyNewBoatWithin5Years', inputType:'radio', options: ['ja', 'nei', 'kanskje']},
					{name: 'Hva slags båttype(r) er du på jakt etter?',propertyName: 'lookingForBoatType', inputType:'multiSelect', options: ['skjærgårdsjeep', 'bow rider', 'daycruiser', 'cabincruiser', 'jolle', 'seilbåt', 'RIB', 'styrhus', 'vannscooter','annet'],
						dependsOn: 'plansToBuyNewBoatWithin5Years', dependsOnValue: ['ja', 'kanskje'], required: true}
				]
			},
		],
		[
			{
				inputs: [
					{name: 'Hva slags innhold er du mest interresert i?',propertyName: 'preferences.categoryLikes', inputType: 'custom', custom:CategoryChooser},
				]
			},
		],
		[
			{
				inputs: [
					{name: 'fødselsdato (ddmmåååå)',propertyName: 'dateOfBirth', inputType: 'text', min:8, max:8, placeholder: 'ddmmåååå'},
				]
			},
			{
				name: 'kjønn',
				inputs: [
					{
						name: 'kjønn',
						propertyName: 'sex',
						inputType: 'radio',
						options: ['mann', 'kvinne', 'ønsker ikke meddele'],
						required: true
					}
				]
			},
		]
	];


	return	<div className={c.root}>
		<OnTopBox onTopEnabled={isVisible} setOnTopEnabled={setIsVisible} removeCloseButton={true}>
			<div className={'centered'}><h2>Hei, {name}</h2></div>
			<FormView questions={questions}
					  sendIn={sendIn}
					  displayStepProgress={true}
					  firstStepExplanation={<p><b>Kan vi få vite mer om deg? Vi lover en bedre brukeropplevelse!</b></p>}
					  postponeFunction={postPone}
			/>
		</OnTopBox>
	</div>
}
