import SiteService from "./SiteService";
import ContentBlock1 from "../components/frontpage/content-blocks/ContentBlock1";
import ContentBlock3 from "../components/frontpage/content-blocks/ContentBlock3";
import ContentBlock6 from "../components/frontpage/content-blocks/ContentBlock6";
import ContentBlock5 from "../components/frontpage/content-blocks/ContentBlock5";
import ContentBlock2 from "../components/frontpage/content-blocks/ContentBlock2";
import AdvertBlock1 from "../components/frontpage/content-blocks/AdvertBlock1";
import ContentBlock7 from "../components/frontpage/content-blocks/ContentBlock7";
import AdvertBlock2 from "../components/frontpage/content-blocks/AdvertBlock2";
import AdvertBlock3 from "../components/frontpage/content-blocks/AdvertBlock3";
import AdvertBlock4 from "../components/frontpage/content-blocks/AdvertBlock4";
import AdvertBlock5 from "../components/frontpage/content-blocks/AdvertBlock5";
import AdvertBlock6 from "../components/frontpage/content-blocks/AdvertBlock6";
import VideoBlock1 from "../components/frontpage/content-blocks/VideoBlock1";
import BatsokBlock1 from "../components/frontpage/content-blocks/BatsokBlock1";
import DividerLine from "../components/frontpage/content-blocks/DividerLine";
import ContentBlock8 from "../components/frontpage/content-blocks/ContentBlock8";
import VideoBlockBig from "../components/frontpage/content-blocks/VideoBlockBig";
import NyhetsbrevAbonner from "../components/frontpage/NyhetsbrevAbonner";
import AdvertBlock8 from "../components/frontpage/content-blocks/AdvertBlock8";
import Helpers from "../helpers/Helpers";

const FrontpageService = {};

FrontpageService.getAll = async () => {
	let res = await fetch(`${SiteService.apiPath}/frontpages`);
	return await res.json();
}
FrontpageService.getOne = async (id) => {
	try {
		let res = await fetch(`${SiteService.apiPath}/frontpage/${id}`);
		if (!res.ok) {
			throw new Error('Failed to fetch data');
		}
		return await res.json();
	} catch (error) {
		console.error('Error fetching data:', error);
		return null;
	}
}
FrontpageService.getOneWithContent = async (id) => {
	try {
		let res = await fetch(`${SiteService.apiPath}/frontpage-content/${id}`);
		if (!res.ok) {
			throw new Error('Failed to fetch data');
		}
		return await res.json();
	} catch (error) {
		console.error('Error fetching data:', error);
		return null;
	}
}


FrontpageService.getFrontpageWithContent = async (frontpage) => {
	if (frontpage){
		let res = await fetch(`${SiteService.apiPath}/frontpage-content`, {
			method: 'POST',
			body: JSON.stringify({frontpage: frontpage}),
			headers: { 'Content-Type': 'application/json' }
		});
		return await res.json();
	}
	else {
		let res = await fetch(`${SiteService.apiPath}/frontpage-content`);
		const data = await res.json();
		return data;
	}
}

FrontpageService.getFrontpageWithContentFromLocal = async (frontpage) => {
	let res = await fetch(`${SiteService.apiPath}/frontpage-content`, {
		method: 'POST',
		body: JSON.stringify({frontpage: frontpage}),
		headers: { 'Content-Type': 'application/json' }
	});
	if (res.ok)
		return await res.json();
	else {
		alert('Feil under henting av forside fra server');
		return {modules:[]};
	}

}

FrontpageService.moduleTypes = {
	m1: {
		name: "[3] Artikler1",
		component: ContentBlock1,
		sectionTitle: true,
		parts: ['article','article','article'],
		customStylePerPart: true,
		customStyle: true,
	},
	m2: {
		name: "[3] Artikler1-revers",
		component: ContentBlock1,
		sectionTitle: true,
		parts: ['article','article','article'],
		customStylePerPart: true,
		customStyle: true,
		reverse: true,
	},m6: {
		name: "[1+] Artikler2",
		component: ContentBlock2,
		sectionTitle: true,
		parts: ['article','article','article'],
		minParts: 3,
		customStyle: true,
		slidesPerViewMin: 1
	},
	m18: {
		name: "[2] Dobbel artikkel",
		component: ContentBlock8,
		parts: ['article', 'article'],
		sectionTitle: true,
		customStyle: true,
		customStylePerPart: true
	},
	m3: {
		name: "[1] Artikkel1",
		component: ContentBlock3,
		parts: ['article'],
		customStyle: true,
		customStylePerPart: true,
	},
	m4: {
		name: '[1] Artikkel2',
		component: ContentBlock6,
		parts: ['article'],
		customStyle: true,
		customStylePerPart: true,
	},
	m5: {
		name: '[1] Artikkel Kursiv',
		component: ContentBlock5,
		parts: ['article'],
		customStyle: true,
	},
	m8: {
		name: '[1] Artikkel4',
		component: ContentBlock7,
		parts: ['article'],
		customStyle: true,
		customStylePerPart: true,
	},
	m9: {
		name: '[1] Artikkel4-revers',
		component: ContentBlock7,
		parts: ['article'],
		reverse: true,
		customStyle: true,
	},
	m7: {
		name: '[1] Annonse- Native',
		component: AdvertBlock1,
		parts: ['ad'],
		customStyle: true,
	},
	m10: {
		name: '[2] Annonse- Firkanter',
		component: AdvertBlock2,
		parts: ['ad', 'ad'],
		customStyle: true,
	},
	m11: {
		name: '[2] Annonse- Søyle + artikkel',
		component: AdvertBlock3,
		parts: ['ad', 'article'],
		customStyle: true,
		customStylePerPart: true,
	},
	m12: {
		name: '[1] Annonse- Brandboard XL',
		component: AdvertBlock4,
		parts: ['ad'],
		customStyle: true,
	},
	m13: {
		name: '[1] Annonse- Brandboard',
		component: AdvertBlock5,
		parts: ['ad'],
		customStyle: true,
	},
	m14: {
		name: '[1] Annonse- Banner',
		component: AdvertBlock6,
		parts: ['ad'],
		customStyle: true,
	},
	m21: {
		name: '[1] Annonse- Netboard',
		component: AdvertBlock8,
		parts: ['ad'],
		customStyle: true,
	},
	m19: {
		name: '📺 Video',
		component: VideoBlockBig,
		parts: ['video'],
		sectionTitle: true,
		customStyle: true,
	},
	m15: {
		name: '📺 Videoer',
		component: VideoBlock1,
		parts: ['video', 'video', 'video'],
		sectionTitle: true,
		customStyle: true,
		minParts: 3,
		slidesPerViewMin: 1
	},
	m16: {
		name: 'Båtsøk',
		type: 'batsok',
		component: BatsokBlock1,
		parts: ['batsok', 'batsok', 'batsok'],
		sectionTitle: true,
		customStyle: true,
		minParts: 3,
		slidesPerViewMin: 3
	},
	m20: {
		name: 'Nyhetsbrev',
		component: NyhetsbrevAbonner
	},
	m17: {
		name: '------Skillelinje',
		type: 'dividerLine',
		component: DividerLine,
		customStyle: true,
	},
}

FrontpageService.FrontpageRender = function({frontpageModules, isLive, categories}){
	return(<>
	{frontpageModules.map((module, index)=>{
		let moduleType = FrontpageService.moduleTypes[module.moduleType];
		let Module = FrontpageService.moduleTypes[module.moduleType].component;

		for (let i = 0; i < module.parts.length; i++) {
			Helpers.prepareArticleForFrontpageParts(module.parts[i], categories);
		}

		// render the module's component
		return (<section key={index} id={'module'+index}>
			<Module
				parts={isLive?module.parts:null}
				reverse={moduleType.reverse}
				sectionTitle={module.sectionTitle}
				customStyle={module.style}
				slidesPerView={module.slidesPerView}
				link={module.link}
			/></section>)
	})}</>)
}

export default FrontpageService;