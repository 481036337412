import {useId, useState} from "react";
import CheckmarkIcon from "../../svg/CheckmarkIcon";

export default function Checkbox({changeFunc, state, setState, ref, idOverride, label, style, disabled}) {
	const [checkedLocalState, setCheckedLocalState] = useState(state?state:false);
	let checked = state?state:checkedLocalState;
	const id = useId();
	const handleChange = (ev) => {
		setCheckedLocalState(!checked);
		if(changeFunc) changeFunc(!checked);
		if(setState) setState(!checked);
	};

	return (
		<div style={style?style:null} className={["checkbox1", checked?'checked':''].join(' ')}>
			<label htmlFor={idOverride?idOverride:id}><CheckmarkIcon/></label>
			{label&& <label htmlFor={idOverride ? idOverride : id}>{label}</label>}
			<input type="checkbox" ref={ref?ref:null} checked={checked} onChange={handleChange} value={state} className={'hidden'}
				   id={idOverride?idOverride:id}
				   disabled={disabled}
			/>
		</div>
	)
}