import SiteService from "./SiteService";

const AdvertService = {
	//todo remove this temp
	incrementTempad: async function(eventOrString){
		let target = (typeof eventOrString === "string")? eventOrString : eventOrString.currentTarget.href.toString().replaceAll('/','-');

		return await fetch(`${SiteService.apiPath}/incrementtempad/`+target, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json'
			}
		});
	},

	mobileMaxWidthPx: 810,

	getAll: async function(cb) {
		try {
			const response = await fetch(
				`${SiteService.apiPath}/advert/all`,
				{
					method: "GET",
				}
			);
			const data = await response.json();
			//sort by name
			if (cb)
				cb(data);
			else
				return data;
		} catch (err) {
			console.error(err);
		}
	},

	getOne: async function(id){try {
		let response = await fetch(`${SiteService.apiPath}/advert/${id}`);
		const data = await response.json();
		return data;
	}catch (e) {
		console.error(e);
		return null;
	}},

	getRandomAd: function(cb){
		fetch(
			`${SiteService.apiPath}/advert/random`,
			{
				method: "GET",
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			cb(data);
		})
	},
	getIncrementalAd: function(cb){
		fetch(
			`${SiteService.apiPath}/advert/incremental`,
			{
				method: "GET",
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			cb(data);
		})
	},

	create: function(ad: {name: String, description: String, link: String, _id: String, sponsor: String}, cb){
		fetch(
			`${SiteService.apiPath}/advert/upsert`,
			{
				method: "POST",
				body: JSON.stringify({
					name: ad.name,
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success) {
				console.log('saved cat: ' + data.advert.name + ' with Id: ' + data.advert._id);
				if(cb)
					cb(data.advert);
			}
		})
	},

	save: function(ad: {}, cb){
		console.log(ad)
		fetch(
			`${SiteService.apiPath}/advert/upsert`,
			{
				method: "POST",
				body: JSON.stringify({
					...ad
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success) {
				console.log('saved cat: ' + data.advert.name + ' with Id: ' + data.advert._id);
				if(cb)
					cb(data.advert);
			}
		})
	},

	delete: function (id, cb){
		if (!confirm("Er du sikker på at du vil slette annonsen?"))
			return;
		fetch(
			`${SiteService.apiPath}/advert/delete`,
			{
				method: "POST",
				body: JSON.stringify({
					id: id
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success) {
				console.log('deleted cat with id: ' + id);
				if(cb)
					cb(true);
			}
		})
	},

	toggleActive: function (id, cb){
		fetch(
			`${SiteService.apiPath}/advert/toggleactive`,
			{
				method: "POST",
				body: JSON.stringify({
					id: id
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success) {
				console.log('toggled ad with id: ' + id);
				if(cb)
					cb(true);
			}else{
				if(cb)
					cb(false);
			}
		})
	},

	advertOnclick: function (id){
		fetch(
			`${SiteService.apiPath}/advert/click`,
			{
				method: "POST",
				body: JSON.stringify({id: id}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then(()=>{return null});
	},

	getPage: function(pageNumber, sortBy, searchObj, limit, cb){
		fetch(
			`${SiteService.apiPath}/admin/getadvertpage`,
			{
				method: "POST",
				body: JSON.stringify({
					pageOptions: {page: pageNumber, sortBy: sortBy, searchObj: searchObj, limit:limit}
				}),
				headers: {'Content-Type': 'application/json'}
			}
		).then((response)=> {
			return response.json();
		})
			.then((data)=> {
				if(cb) cb(data);
			})
			.catch(reason => {
				console.error(reason);
				alert('Feil under henting av artikler');
			})
	},
};

export default AdvertService;