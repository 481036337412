import SiteService from "../../services/SiteService";
import {forwardRef, useContext, useEffect, useRef, useState} from "react";
import PlayIcon from "../../svg/PlayIcon";
import {Link} from "react-router-dom";
import userContext from "../../store/userContext";
const playPause = (ref) => {
	// pause all videos except the one we clicked on
	document.querySelectorAll("video").forEach(v => {
		if(v == ref.current)
			return;
		if (!v.autoplay)
			!v.paused && v.pause()
	});
	ref.current.paused ? ref.current.play() : ref.current.pause();
}

const getVideoDetails = async (videoId) => {
	let res = await fetch(`${SiteService.apiPath}/video/${videoId}`);
	return res.json();
}

const linkClickInc = async (id, role) => {
	if (role > 0) return;
	let res = await fetch(`${SiteService.apiPath}/video/${id}/linkclick`);
}

const VideoPlayer = forwardRef(function VideoPlayer({ videoId, disableControls, autoPlay, slowDown, index, maxIndex }, ref) {
	const user = useContext(userContext);
	const videoRef = ref?ref : useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [videoDetails, setVideoDetails] = useState({});
	const [videoDurationText, setVideoDurationText] = useState(null);
	useEffect(() => {
		if (videoId)
			getVideoDetails(videoId).then(res => {
				setVideoDetails(res);
			});
	}, [videoId])

	const onLoadedMetadata = (()=>{
		// set video duration to "Xs" if seconds. If divisible by 60, set to "X:ss"
		let videoDuration = videoRef.current.duration;
		let minutes = Math.floor(videoDuration / 60);
		let seconds = Math.floor(videoDuration) % 60;
		if (minutes > 0) {
			if (seconds.toString().length < 2) {
				seconds = '0' + seconds;
			}
			setVideoDurationText(`${minutes}:${seconds}`);
		}
		else {
			setVideoDurationText(`${seconds}s`);
		}
	})

	function ProgressBar(){
		let [progress, setProgress] = useState(0);

		useEffect(()=>{
			// timer to update progress bar
			let timer = setInterval(()=>{
				try{setProgress(videoRef.current.currentTime / videoRef.current.duration)}catch (e) {
					clearInterval(timer);
				}
					if(progress>1) clearInterval(timer);
				}, 100);
			return ()=>clearInterval(timer);
		}, [])


		return <div className={'videoProgressBar'} style={{marginTop:'-20px',width: `${progress*100}%`}}>
		</div>;
	}

	return(

	<div className={['videoPlayer', isPlaying?'isPlaying':''].join(' ')} style={{pointerEvents:(autoPlay&&disableControls===undefined)?'none':''}}>
		<video onClick={(ev)=> {playPause(videoRef)}} ref={videoRef} unselectable={'true'}
			   className={'animationFade-in'}
			   disableRemotePlayback={true}
			   playsInline={true}
			   controlsList="nodownload" disablePictureInPicture={true} preload={"none"}
			   poster={videoDetails.thumbnailExtension&& `${SiteService.apiPath}/image/video-thumbnails/${videoId}`}
			   onPlay={()=>{setIsPlaying(true); if(autoPlay&&slowDown==undefined)videoRef.current.playbackRate = 0.5}}
			   onPause={()=>{setIsPlaying(false)}}
			   onLoadedMetadata={onLoadedMetadata}
			   autoPlay={autoPlay}
			   muted={(autoPlay && slowDown===undefined)}
			   loop={autoPlay}
			   src={`${SiteService.apiPath}/video/stream/${videoId}`}
		>
		</video>

		{!disableControls&& <>
			{(!isPlaying&&videoDetails.durationSeconds)&&<div className={'videoDuration'}>{videoDetails.durationSeconds + 's'}</div>}

			{(videoDetails.name)&&<div className={'videoOverlay'}>
				<div className={'videoOverlayText'}>
					<div className={'videoTitle'}>{videoDetails.name}</div>
				</div>
			</div>}

			{(index>-1&&maxIndex)&& <div className={'videoIndex'}>{index + 1} / {maxIndex}</div>}

			{videoDetails.sponsored&& <div className={'videoSponsored'}>Sponset</div>}

			{videoDetails.link && (
				<>
					{videoDetails.link.startsWith('/') ? (
						<Link to={videoDetails.link} className={'videoLink'} onClick={()=>linkClickInc(videoId,user.role)}>
							{videoDetails.linkText?videoDetails.linkText:'Se mer'}
						</Link>
					) : (
						<a href={videoDetails.link} className={'videoLink'} onClick={()=>linkClickInc(videoId,user.role)}>
							{videoDetails.linkText?videoDetails.linkText:'Se mer'}
						</a>
					)}
				</>
			)}


			<button type={"button"} className={'playButton'} onClick={()=> {playPause(videoRef)}}>
				<PlayIcon>⏵</PlayIcon>
			</button>
			<ProgressBar/>
		</>}

	</div>
)});

export default VideoPlayer;