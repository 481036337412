import adPlacementService from "../../../services/AdPlacementService";
import {useContext, useRef, useState} from "react";
import SiteService from "../../../services/SiteService";
import advertService from "../../../services/AdvertService";
import ContentPart1 from "../ContentPart1";
import ContentBlockWrapper from "../ContentBlockWrapper";
import useInView from "../../../hooks/useInView";
import advertImage from "../../../images/adv-3.webp";
import advertMobileImage from "../../../images/adv-mob.webp";
import userContext from "../../../store/userContext";
import Helpers from "../../../helpers/Helpers";
import AdvertTrackPixelImage from "../../advert/AdvertTrackPixelImage";

export default function AdvertBlock3({parts, customStyle}) { // søyle
	if (!parts)
		parts = [{}, {}];

	const [ad, setAd] = useState(null);


	const adRef = useRef();

	const role = useContext(userContext).role;
	const handleInView = () => {
		if(!parts[0].id || ad) return;
		adPlacementService.getById(parts[0].id, (ad)=>{
			ad.wideImage = Helpers.getRandomFromArray(ad.wideImages);
			ad.mobileImage = Helpers.getRandomFromArray(ad.mobileImages);
			ad.link = (window.innerWidth>advertService.mobileMaxWidthPx)? ad.wideLink : (ad.mobileLink?ad.mobileLink:ad.wideLink);
			setAd(ad);
		}, role > 0)
	};
	useInView(adRef, {rootMargin:'100px'}, handleInView, true);


	return <><ContentBlockWrapper customStyle={customStyle ? customStyle : null}>
		<div ref={adRef} className={'advertBlock3 advBlock contentBlock contentBlock1Inner reverse'}>
			<div className={'contentBlock1Area2'}>
				<ContentPart1 part={parts[1]}/>
			</div>
			<a href={ad && ad.link ? ad.link : ''} target={"_blank"}
			   onClick={() => {
				   if (role<1)advertService.advertOnclick(ad._id)
			   }}>
				<picture>
					<source
						media={`(max-width: ${advertService.mobileMaxWidthPx}px)`}
						srcSet={(ad && ad.mobileImage) ? `${SiteService.apiPath}/image/adv/${ad._id}/${ad.mobileImage}` : advertMobileImage}
					/>
					<img
						className="advertBlock3Image"
						src={(ad && ad.wideImage) ? `${SiteService.apiPath}/image/adv/${ad._id}/${ad.wideImage}` : advertImage}
						sizes={`(max-width: ${advertService.mobileMaxWidthPx}px) 100vw, ${advertService.mobileMaxWidthPx}px`}
						alt="Annonse"
					/>
				</picture>
			</a>
		</div>
	</ContentBlockWrapper>

		<AdvertTrackPixelImage ad={ad}/>

	</>
}