import advertService from "../../../services/AdvertService";
import SiteService from "../../../services/SiteService";
import {useContext, useRef, useState} from "react";
import adPlacementService from "../../../services/AdPlacementService";
import ContentBlockWrapper from "../ContentBlockWrapper";
import useInView from "../../../hooks/useInView";
import advertImage from "../../../images/adv-5.webp";
import advertMobileImage from "../../../images/adv-mob.webp";
import userContext from "../../../store/userContext";
import Helpers from "../../../helpers/Helpers";
import AdvertTrackPixelImage from "../../advert/AdvertTrackPixelImage";

export default function AdvertBlock5({parts, adPlacementId, customStyle}){ // brandboard
	if (!parts)
		parts = [{}];

	const id = adPlacementId?adPlacementId:parts[0].id;

	const [ad, setAd] = useState(null);

	const adRef = useRef();
	const role = useContext(userContext).role;

	const handleInView = () => {
		if(!id || ad) return;
		adPlacementService.getById(id, (ad)=>{
			ad.wideImage = Helpers.getRandomFromArray(ad.wideImages);
			ad.mobileImage = Helpers.getRandomFromArray(ad.mobileImages);
			ad.link = (window.innerWidth>advertService.mobileMaxWidthPx)? ad.wideLink : (ad.mobileLink?ad.mobileLink:ad.wideLink);
			setAd(ad);
		}, role > 0)
	};
	useInView(adRef, {rootMargin:'100px'}, handleInView, true);


	return <><ContentBlockWrapper customStyle={customStyle ? customStyle : null}
	><a ref={adRef} className={'advertBlock5 advBlock contentBlock'} href={ad && ad.link ? ad.link : ''}
		target={"_blank"}
		onClick={() => {
			if (role<1)advertService.advertOnclick(ad._id)
		}}>
		<picture>
			<source
				media={`(max-width: ${advertService.mobileMaxWidthPx}px)`}
				srcSet={(ad && ad.mobileImage) ? `${SiteService.apiPath}/image/adv/${ad._id}/${ad.mobileImage}` : advertMobileImage}
			/>
			<img
				className="advertBlock5Image"
				src={(ad && ad.wideImage) ? `${SiteService.apiPath}/image/adv/${ad._id}/${ad.wideImage}` : advertImage}
				sizes={`(max-width: ${advertService.mobileMaxWidthPx}px) 100vw, ${advertService.mobileMaxWidthPx}px`}
				alt="Annonse"
			/>
		</picture>
	</a>
	</ContentBlockWrapper>

		<AdvertTrackPixelImage ad={ad}/>

	</>
}