import adPlacementService from "../../../services/AdPlacementService";
import {useContext, useRef, useState} from "react";
import SiteService from "../../../services/SiteService";
import advertService from "../../../services/AdvertService";
import ContentBlockWrapper from "../ContentBlockWrapper";
import useInView from "../../../hooks/useInView";
import advertImage from "../../../images/adv-1.webp"
import userContext from "../../../store/userContext";
import Helpers from "../../../helpers/Helpers";
import AdvertTrackPixelImage from "../../advert/AdvertTrackPixelImage";

export default function AdvertBlock1({parts, customStyle, adPlacementId}) { // native ad
	if (!parts)
		parts = [{}]

	const id = adPlacementId?adPlacementId:parts[0].id;

	const [ad, setAd] = useState(null);

	const adRef = useRef();
	const role = useContext(userContext).role;

	const handleInView = () => {
		if(!id || ad) return;
		adPlacementService.getById(id, (ad)=>{
			ad.wideImage = Helpers.getRandomFromArray(ad.wideImages);
			ad.link = (window.innerWidth>advertService.mobileMaxWidthPx)? ad.wideLink : (ad.mobileLink?ad.mobileLink:ad.wideLink);
			//ad.mobileImage = Helpers.getRandomFromArray(ad.mobileImages);
			setAd(ad);
		}, role > 0)
	};
	useInView(adRef, {rootMargin:'100px'}, handleInView, true);

	 return <><ContentBlockWrapper customStyle={customStyle ? customStyle : null}>
		 <a ref={adRef} href={(ad && ad.link) ? ad.link : ''} target={"_blank"}
			className={'advertBlock1 advBlock contentBlock'}
			onClick={() => {
				if (role<1)advertService.advertOnclick(ad._id)
			}}>
			 <div className={'advertBlock1Part1'}>
				 {ad?.sponsor?.name ? 'ANNONSØR: ' + ad?.sponsor?.name : 'ANNONSE'}
			 </div>
			 <div className={'advertBlock1Part2'}>
				 <img className={'advertBlock1Image'}
					  src={(ad && ad.wideImage) ? `${SiteService.apiPath}/image/adv/${ad._id}/${ad.wideImage}` : advertImage}/>
			 </div>
			 <div className={'advertBlock1Part3'}>
				 <div className={'advertBlock1Part3-1'}>

					 <h3>{(ad && ad.description) ? ad.description : 'Annonse Annonse Annonse Annonse Annonse Annonse Annonse Annonse Annonse Annonse Annonse'}</h3>
				 </div>
				 {(ad?.sponsor?.logoImage) && <img className={'advertBlock1Logo'}
												   src={`${SiteService.apiPath}/image/sponsors/${ad.sponsor._id + ad.sponsor.logoImage}`}/>}
			 </div>
		 </a>
	 </ContentBlockWrapper>

	 <AdvertTrackPixelImage ad={ad}/>
	 </>
}