import SiteService from "../../../services/SiteService";
import {Link} from "react-router-dom";
import ContentImage from "../ContentImage";
import ContentBlockWrapper from "../ContentBlockWrapper";

export default function ContentBlock6({parts, customStyle}){
	let part = {};
	if (!parts) {
		console.log('ContentBlock6 has no parts');
		part = {
			imageLink: `${SiteService.apiPath}/image/articles/640f3f77081a28d9d3a1d4db/header.jpg`,
			name: 'Gå til bæsj og ta en drit i bar',
			preface: 'KJAWdh paiudhawiu dhOAWUd hawdh9 129hd921q nd+2" AHD aWd AdlkjAHWd hAK DwAd øhA',
		}
	}else{
		part = parts[0];
	}

	if(part.isSponsored)
		part.category = 'Annonsørinnhold';

	 return <ContentBlockWrapper customStyle={customStyle?customStyle:null}>
		 <Link to={part.linkTo?part.linkTo:''} className={'contentBlock6 contentBlock'} style={part.style?part.style:null}>
		 <div className={'contentBlock6Part1'}>
			 <ContentImage part={part}>
			 	<img loading={"lazy"} src={part.imageLink}/>
			 </ContentImage>
		 </div>
		 <div className={'contentBlock6Part2 contentTextContainer'}>
			 <h3>{part.name}</h3>
			 <p>{part.preface}</p>
			 <div
				 className={['category categoryInImage categoryBoxThemed', part.isSponsored ? 'sponsored' : '',
					 part.isOpinionPiece ? 'opinionPiece' : ''
				 ].join(' ')}
				 style={part.style ? part.style : null}>{part.category}</div>
		 </div>
		 </Link>
	 </ContentBlockWrapper>
}