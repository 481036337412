
const litenBat = {
	title: 'Liten båt',
	logo: 'knbf',
	formDescription: 'Roddbåt, jolle eller annan enkel öppen båt utan motor.',
	tableQuestions: [

		{header: '1. Er båten fri fra vann'},
		{text: 'På land - skrovets utsida', legendIcon: 'y', modalContent: <p>BIDADAWIUBDAW</p>},
		{text: 'På land - dyvika', legendIcon: 'y'},
		{text: 'På land - sjalvansa', legendIcon: 'y'},
		{text: 'I sjøen - lyft på durk', legendIcon: 'y'},
		{text: 'I sjøen - kontollera',legendIcon: 'b'},
		{text: 'I sjøen - kontollera',legendIcon: 'b'},

		{header: '2. Flytsakarhet'},
		{text: 'Finns tillrack', legendIcon: ''},
		{text: 'Vid båt med flyttankar', legendIcon: ''},

		{header: '3. Sakkarhet'},
		{text: 'Ankare', legendIcon: ''},
		{text: 'Båtshake', legendIcon: ''},
		{text: 'CE-Markar', legendIcon: ''},
		{text: 'Fendrar', legendIcon: ''},
		{text: 'Flytvastar', legendIcon: ''},
	]
}

export default litenBat;